<div class="dashboard-company pb-3">
    <div class="left">
        <div class="item item_toal_revenue">
            <div class="flex justify-between">
                <span class="item_title">Total Revenue</span>
                @if(loading){
                    <div class="preloader_price">
                        <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                    </div>
                } @else {
                    <button class="item_btn" (click)="viewReportTotalRevenue()">View full report</button>
                }
            </div>
            <div class="flex justify-between items-end subtitle">
                @if(loading){
                    <div class="preloader_price">
                        <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                    </div>
                } @else {
                    <span class="subtitle-value">${{ totalRevenueAmount }}</span>
                }
                <span class="subtitle-date">Data updates every 3 Hours</span>
            </div>
            <div class="stats-lines py-2">
                <canvas id="line" [ngStyle]="{'opacity': loading ? 0 : 1, 'position': loading ? 'absolute' : 'relative'}" chartLines></canvas>
                @if(loading){
                    <div class="preloader_charts h-full">
                        <div class="preloader_chart_prices">
                            <div class="preloader_chart_price">
                                <app-preloader [type]="'LARGE_LIST'" [customHeight]="'10px'" [count]="1"></app-preloader>
                            </div>
                            <div class="preloader_chart_price">
                                <app-preloader [type]="'LARGE_LIST'" [customHeight]="'10px'" [count]="1"></app-preloader>
                            </div>
                            <div class="preloader_chart_price">
                                <app-preloader [type]="'LARGE_LIST'" [customHeight]="'10px'" [count]="1"></app-preloader>
                            </div>
                            <div class="preloader_chart_price">
                                <app-preloader [type]="'LARGE_LIST'" [customHeight]="'10px'" [count]="1"></app-preloader>
                            </div>
                            <div class="preloader_chart_price">
                                <app-preloader [type]="'LARGE_LIST'" [customHeight]="'10px'" [count]="1"></app-preloader>
                            </div>
                        </div>
                        <div class="preloader_chart_img">
                            <div class="w-full h-full preloader_chart_img_bg">
                                <app-preloader [type]="'LARGE_LIST'" [customHeight]="'100%'" [count]="1" [options]="{h_full: true}"></app-preloader>
                            </div>
                            <div class="preloader_chart_dates">
                                <div class="preloader_chart_date">
                                    <app-preloader [type]="'LARGE_LIST'" [customHeight]="'17px'" [count]="1"></app-preloader>
                                </div>
                                <div class="preloader_chart_date">
                                    <app-preloader [type]="'LARGE_LIST'" [customHeight]="'17px'" [count]="1"></app-preloader>
                                </div>
                                <div class="preloader_chart_date">
                                    <app-preloader [type]="'LARGE_LIST'" [customHeight]="'17px'" [count]="1"></app-preloader>
                                </div>
                                <div class="preloader_chart_date">
                                    <app-preloader [type]="'LARGE_LIST'" [customHeight]="'17px'" [count]="1"></app-preloader>
                                </div>
                                <div class="preloader_chart_date">
                                    <app-preloader [type]="'LARGE_LIST'" [customHeight]="'17px'" [count]="1"></app-preloader>
                                </div>
                                <div class="preloader_chart_date">
                                    <app-preloader [type]="'LARGE_LIST'" [customHeight]="'17px'" [count]="1"></app-preloader>
                                </div>
                                <div class="preloader_chart_date">
                                    <app-preloader [type]="'LARGE_LIST'" [customHeight]="'17px'" [count]="1"></app-preloader>
                                </div>
                            </div>
                        </div>
                    </div>
                } @else {
                    <div></div>
                }
            </div>
            <div class="flex justify-between item-footer">
                <div></div>
                <div class="flex items-center">
                    <div class="flex items-center">
                        @if(loading){
                            <div class="preloader_price">
                                <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                            </div>
                        } @else {
                            <div class="sign" style="background: #D02323;"></div>
                            <span class="item_title">Transport</span>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div class="item bottom_item">
            <div class="flex justify-between profit_block">
                <span class="profit_title">Profit</span>
            </div>
            <div>
                <div class="doughnut-chart-wr">
                    <div class="doughnut-chart relative">
                        <canvas id="doughnut" >{{ chart }}</canvas>
                        <div class="doughnut_value" [ngClass]="{'doughnut_value_small': _profit?.toString()?.length > 9, 'doughnut_value_extra_small': _profit?.toString()?.length > 11}">
                            @if(loading){
                                <div class="preloader_price">
                                    <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                </div>
                            } @else {
                                <p>${{ _profit }}</p>
                            }
                            <span>Total</span>
                        </div>
                    </div>
                    <div class="doughnut_items_wr">
                        @if(loading){
                            <div class="preloader_price">
                                <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                            </div>
                            <div class="preloader_price mt-2">
                                <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                            </div>
                        } @else {
                            <div class="doughnut_items">
                                <div class="sign" style="background: #278A3C;"></div>
                                <span>Long Haul</span>
                            </div>
                            <div class="doughnut_items">
                                <div class="sign" style="background: #2669DC;"></div>
                                <span>Local</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="right">
        <div class="top-right">
            <div class="item item_monthly_expense">
                <div class="flex">
                    <span class="item_title">Monthly Expense</span>
                </div>
                <div class="flex justify-between items-end subtitle pt-2">
                    @if(loading){
                        <div class="preloader_price">
                            <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                        </div>
                    } @else {
                        <span class="subtitle-value">${{ monthlyExpenseTotal }}</span> <br>
                    }
                    <span class="subtitle-date">Data updates every 3 Hours</span>
                </div>
                <div class="monthly_expense">
                    <canvas #barChart [ngStyle]="{'opacity': loading ? 0 : 1, 'position': loading ? 'absolute' : 'relative'}"></canvas>
                    @if(loading){
                        <div class="preloader_bar" style="flex: 1;">
                            <div class="preloader_chart_prices">
                                <div class="preloader_chart_price">
                                    <app-preloader [type]="'LARGE_LIST'" [customHeight]="'10px'" [count]="1"></app-preloader>
                                </div>
                                <div class="preloader_chart_price">
                                    <app-preloader [type]="'LARGE_LIST'" [customHeight]="'10px'" [count]="1"></app-preloader>
                                </div>
                                <div class="preloader_chart_price">
                                    <app-preloader [type]="'LARGE_LIST'" [customHeight]="'10px'" [count]="1"></app-preloader>
                                </div>
                                <div class="preloader_chart_price">
                                    <app-preloader [type]="'LARGE_LIST'" [customHeight]="'10px'" [count]="1"></app-preloader>
                                </div>
                                <div class="preloader_chart_price">
                                    <app-preloader [type]="'LARGE_LIST'" [customHeight]="'10px'" [count]="1"></app-preloader>
                                </div>
                            </div>
                            <div class="preloader_chart_img">
                                <div class="w-full h-full preloader_chart_img_bg">
                                    <app-preloader [type]="'LARGE_LIST'" [customHeight]="'100%'" [count]="1" [options]="{h_full: true}"></app-preloader>
                                </div>
                                <div class="preloader_chart_dates">
                                    <div class="preloader_chart_date">
                                        <app-preloader [type]="'LARGE_LIST'" [customHeight]="'17px'" [count]="1"></app-preloader>
                                    </div>
                                    <div class="preloader_chart_date">
                                        <app-preloader [type]="'LARGE_LIST'" [customHeight]="'17px'" [count]="1"></app-preloader>
                                    </div>
                                    <div class="preloader_chart_date">
                                        <app-preloader [type]="'LARGE_LIST'" [customHeight]="'17px'" [count]="1"></app-preloader>
                                    </div>
                                    <div class="preloader_chart_date">
                                        <app-preloader [type]="'LARGE_LIST'" [customHeight]="'17px'" [count]="1"></app-preloader>
                                    </div>
                                    <div class="preloader_chart_date">
                                        <app-preloader [type]="'LARGE_LIST'" [customHeight]="'17px'" [count]="1"></app-preloader>
                                    </div>
                                    <div class="preloader_chart_date">
                                        <app-preloader [type]="'LARGE_LIST'" [customHeight]="'17px'" [count]="1"></app-preloader>
                                    </div>
                                    <div class="preloader_chart_date">
                                        <app-preloader [type]="'LARGE_LIST'" [customHeight]="'17px'" [count]="1"></app-preloader>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } @else {
                        <div></div>
                    }
                </div>
                @if(loading){
                    <div class="item-footer flex items-center justify-end">
                        <div class="preloader_price_expense">
                            <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                        </div>
                        <div class="preloader_price_expense">
                            <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                        </div>
                        <div class="preloader_price_expense">
                            <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                        </div>
                        <div class="preloader_price_expense">
                            <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                        </div>
                    </div>
                } @else {
                    <div class="item-footer flex items-center justify-end">
                        <div class="flex items-center mr-3">
                            <div class="sign" style="background: #C3DDFD;"></div>
                            <span class="item_title">Fuel</span>
                        </div>
                        <div class="flex items-center mr-3">
                            <div class="sign" style="background: #76A9FA;"></div>
                            <span class="item_title">Claim</span>
                        </div>
                        <div class="flex items-center mr-3">
                            <div class="sign" style="background: #3F83F8;"></div>
                            <span class="item_title">Driver Pay</span>
                        </div>
                        <div class="flex items-center">
                            <div class="sign" style="background: #475565;"></div>
                            <span class="item_title">Shop</span>
                        </div>
                    </div>
                }
            </div>
            <div class="top-right-stats">
                <div class="item item_right_stats">
                    <div class="stats_flex">
                        <div class="stats_left">
                            <div class="stats_item">
                                <span class="item_title">Shop</span>
                                @if(loading){
                                    <div class="preloader_price">
                                        <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                    </div>
                                } @else {
                                    <span class="subtitle-value" [ngClass]="{'subtitle-value-small': shop?.toString()?.length > 7, 'subtitle-value-small-extra': shop?.toString()?.length > 10}">${{ shop }}</span>
                                }
                            </div>
                        </div>
                        <div class="stats_right">
                            <div class="stats_item">
                                <span class="item_title">Driver Pay</span>
                                @if(loading){
                                    <div class="preloader_price">
                                        <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                    </div>
                                } @else {
                                    <span class="subtitle-value" [ngClass]="{'subtitle-value-small': totalDriverPay?.toString()?.length > 7, 'subtitle-value-small-extra': totalDriverPay?.toString()?.length > 10}">${{ totalDriverPay }}</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item item_right_stats">
                    <div class="stats_flex">
                        <div class="stats_left">
                            <div class="stats_item">
                                <span class="item_title">Fuel</span>
                                @if(loading){
                                    <div class="preloader_price">
                                        <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                    </div>
                                } @else {
                                    <span class="subtitle-value" [ngClass]="{'subtitle-value-small': fuelCost?.toString()?.length > 7, 'subtitle-value-small-extra': fuelCost?.toString()?.length > 10}">${{ fuelCost }}</span>
                                }
                            </div>
                        </div>
                        <div class="stats_right">
                            <div class="stats_item">
                                <span class="item_title">MPG</span>
                                @if(loading){
                                    <div class="preloader_price">
                                        <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                    </div>
                                } @else {
                                    <span class="subtitle-value">0</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item item_right_stats_line">
                    <div class="stats_between stats_between-claims">
                        <div class="stats_item">
                            <span class="item_title">Claims</span>
                            @if(loading){
                                <div class="preloader_price">
                                    <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                </div>
                            } @else {
                                <span class="subtitle-value" [ngClass]="{'subtitle-value-small': claimsCounts?.all?.count.toString()?.length > 7, 'subtitle-value-small-extra': claimsCounts?.all?.count.toString()?.length > 10}">{{claimsCounts?.all?.count || 0}}</span>
                            }
                        </div>
                        <div class="stats_between_points">
                            @if(loading){
                                <div class="preloader_price_claims">
                                    <app-preloader [type]="'LARGE_LIST'" [count]="1" [customHeight]="'10px'"></app-preloader>
                                </div>
                                <div class="preloader_price_claims">
                                    <app-preloader [type]="'LARGE_LIST'" [count]="1" [customHeight]="'10px'"></app-preloader>
                                </div>
                                <div class="preloader_price_claims">
                                    <app-preloader [type]="'LARGE_LIST'" [count]="1" [customHeight]="'10px'"></app-preloader>
                                </div>
                                <div class="preloader_price_claims">
                                    <app-preloader [type]="'LARGE_LIST'" [count]="1" [customHeight]="'10px'"></app-preloader>
                                </div>
                            } @else {
                                <div class="between_point_item">
                                    <div class="between_point" style="background: #2669DB;"></div>
                                    <span>New</span>
                                </div>
                                <div class="between_point_item">
                                    <div class="between_point" style="background: #288A3D;"></div>
                                    <span>In review</span>
                                </div>
                                <div class="between_point_item">
                                    <div class="between_point" style="background: #DB2B2B;"></div>
                                    <span>Accepted</span>
                                </div>
                                <div class="between_point_item">
                                    <div class="between_point" style="background: #475565;"></div>
                                    <span>Denied</span>
                                </div>
                            }
                        </div>
                    </div>
                    @if(loading){
                        <div class="w-full preloader_claims_line">
                            <app-preloader [type]="'LARGE_LIST'" [count]="1" [customHeight]="'16px'"></app-preloader>
                        </div>
                    } @else {
                        <div class="stats_line w-full">
                            <div [ngStyle]="{'width': claimsCounts?.in_review?.percentage+'%'}" class="stats_line_item stats_line_in_review" *ngIf="claimsCounts?.in_review?.count !== 0" [popper]="statsInReview" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"></div>
                            <div [ngStyle]="{'width': claimsCounts?.accepted?.percentage+'%'}" class="stats_line_item stats_line_accepted" *ngIf="claimsCounts?.accepted?.count !== 0" [popper]="statsAccepted" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"></div>
                            <div [ngStyle]="{'width': claimsCounts?.denied?.percentage+'%'}" class="stats_line_item stats_line_denied" *ngIf="claimsCounts?.denied?.count !== 0" [popper]="statsDenied" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"></div>
                            <!-- <div *ngIf="claimsCounts?.in_review?.count == 0 && claimsCounts?.accepted?.count == 0 && claimsCounts?.denied?.count == 0" class="stats_line_item border border-gray-100 w-full"></div> -->
                             @if (claimsCounts?.in_review?.count == 0 && claimsCounts?.accepted?.count == 0 && claimsCounts?.denied?.count == 0) {
                                <p class="no_claims">No Claims</p>
                             }
                        </div>
                        <popper-content #statsInReview class="z-50">
                            <div class="rounded-lg shadow-lg relative popover_top">
                                <div class="shadow-lg">
                                    <span class="text-center" style="text-wrap: nowrap;">{{claimsCounts?.in_review?.count}} ({{claimsCounts?.in_review?.percentage}}%)</span>
                                </div>
                                <i class="fa-solid fa-caret-down"></i>
                            </div>
                        </popper-content>
                        <popper-content #statsAccepted class="z-50">
                            <div class="rounded-lg shadow-lg relative popover_top">
                                <div class="shadow-lg">
                                    <span class="text-center" style="text-wrap: nowrap;">{{claimsCounts?.accepted?.count}} ({{claimsCounts?.accepted?.percentage}}%)</span>
                                </div>
                                <i class="fa-solid fa-caret-down"></i>
                            </div>
                        </popper-content>
                        <popper-content #statsDenied class="z-50">
                            <div class="rounded-lg shadow-lg relative popover_top">
                                <div class="shadow-lg">
                                    <span class="text-center" style="text-wrap: nowrap;">{{claimsCounts?.denied?.count}} ({{claimsCounts?.denied?.percentage}}%)</span>
                                </div>
                                <i class="fa-solid fa-caret-down"></i>
                            </div>
                        </popper-content>
                    }
                </div>
                <div class="item item_right_stats_line">
                    <div class="stats_between stats_between-expenses">
                        <div class="stats_item">
                            <span class="item_title">Claim Accepted</span>
                            @if(loading){
                                <div class="preloader_price">
                                    <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                </div>
                            } @else {
                                <span class="subtitle-value" [ngClass]="{'subtitle-value-small': claimsAcceptedAmount?.toString()?.length > 7, 'subtitle-value-small-extra': claimsAcceptedAmount?.toString()?.length > 10}">${{ claimsAcceptedAmount }}</span>
                            }
                        </div>
                        <!-- claimsAcceptedCounts -->
                        <div class="stats_between_points">
                            @if(loading){
                                <div class="preloader_price_claims">
                                    <app-preloader [type]="'LARGE_LIST'" [count]="1" [customHeight]="'10px'"></app-preloader>
                                </div>
                                <div class="preloader_price_claims">
                                    <app-preloader [type]="'LARGE_LIST'" [count]="1" [customHeight]="'10px'"></app-preloader>
                                </div>
                                <div class="preloader_price_claims">
                                    <app-preloader [type]="'LARGE_LIST'" [count]="1" [customHeight]="'10px'"></app-preloader>
                                </div>
                            } @else {
                                <div class="between_point_item">
                                    <div class="between_point" style="background: #75A7F7;"></div>
                                    <span>Driver</span>
                                </div>
                                <div class="between_point_item">
                                    <div class="between_point" style="background: #2668DA;"></div>
                                    <span>{{currentUser?.company?.name || 'HaulEx'}}</span>
                                </div>
                                <div class="between_point_item">
                                    <div class="between_point" style="background: #475464;"></div>
                                    <span>Insurance</span>
                                </div>
                            }
                        </div>
                    </div>
                    @if(loading){
                        <div class="w-full preloader_claims_line">
                            <app-preloader [type]="'LARGE_LIST'" [count]="1" [customHeight]="'16px'"></app-preloader>
                        </div>
                    } @else {
                        <div class="stats_line w-full">
                            @if(claimsAcceptedCounts?.driver?.count && claimsAcceptedCounts?.driver?.count !== 0){
                                <div class="stats_line_driver stats_line_item" [ngStyle]="{'width': claimsAcceptedCounts?.driver?.percentage+'%'}" [popper]="statsLineDriver" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"></div>
                            }
                            @if(claimsAcceptedCounts?.carrier?.count && claimsAcceptedCounts?.carrier?.count !== 0){
                                <div class="stats_line_carrier stats_line_item" [ngStyle]="{'width': claimsAcceptedCounts?.carrier?.percentage+'%'}" [popper]="statsLineCarrier" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"></div>
                            }
                            @if(claimsAcceptedCounts?.insurance?.count && claimsAcceptedCounts?.insurance?.count !== 0){
                                <div class="stats_line_insurance stats_line_item" [ngStyle]="{'width': claimsAcceptedCounts?.insurance?.percentage+'%'}" [popper]="statsLineInsurance" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"></div>
                            }
                            <!-- <div *ngIf="claimsAcceptedCounts?.driver?.count == 0 && claimsAcceptedCounts?.carrier?.count == 0 && claimsAcceptedCounts?.insurance?.count == 0" class="stats_line_item border border-gray-100 w-full"></div> -->
                            @if (claimsAcceptedCounts?.driver?.count == 0 && claimsAcceptedCounts?.carrier?.count == 0 && claimsAcceptedCounts?.insurance?.count == 0) {
                                <p class="no_claims">No Claim Accepted</p>
                             }
                        </div>
                        <popper-content #statsLineDriver class="z-50">
                            <div class="rounded-lg shadow-lg relative popover_top">
                                <div class="shadow-lg">
                                    <span class="text-center">{{claimsAcceptedCounts?.driver?.amount / 100}} ({{claimsAcceptedCounts?.driver?.percentage}}%)</span>
                                </div>
                                <i class="fa-solid fa-caret-down"></i>
                            </div>
                        </popper-content>
                        <popper-content #statsLineCarrier class="z-50">
                            <div class="rounded-lg shadow-lg relative popover_top">
                                <div class="shadow-lg">
                                    <span class="text-center">{{claimsAcceptedCounts?.carrier?.amount / 100}} ({{claimsAcceptedCounts?.carrier?.percentage}}%)</span>
                                </div>
                                <i class="fa-solid fa-caret-down"></i>
                            </div>
                        </popper-content>
                        <popper-content #statsLineInsurance class="z-50">
                            <div class="rounded-lg shadow-lg relative popover_top">
                                <div class="shadow-lg">
                                    <span class="text-center">{{claimsAcceptedCounts?.insurance?.amount / 100}} ({{claimsAcceptedCounts?.insurance?.percentage}}%)</span>
                                </div>
                                <i class="fa-solid fa-caret-down"></i>
                            </div>
                        </popper-content>
                    }
                </div>
            </div>
        </div>
        <div class="item bottom_item">
            <div class="flex justify-between items-center profit_block border-none">
                <div class="top_block flex items-center">
                    <span class="profit_title">Top</span>
                    <div class="top_block_tabs">
                        @if(loading){
                            <div class="flex items-center">
                                <div class="preloader_price">
                                    <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                </div>
                                <div class="preloader_price ml-2">
                                    <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                </div>
                                <div class="preloader_price ml-2">
                                    <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                </div>
                            </div>
                        } @else {
                            <button (click)="topActive = 'dispatchers'" [ngClass]="{'top-active': topActive == 'dispatchers'}">Dispatcher</button>
                            <button (click)="topActive = 'customers'" [ngClass]="{'top-active': topActive == 'customers'}">Customer</button>
                            <button (click)="topActive = 'drivers'"[ngClass]="{'top-active': topActive == 'drivers'}">Driver</button>
                        }
                    </div>
                </div>
                @if(loading){
                    <div class="preloader_price">
                        <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                    </div>
                } @else {
                    <div class="flex items-center gap-2">
                        <a routerLink="/dashboards/tv" class="item_btn flex items-center">Open For TV</a>
                        <button class="item_btn flex items-center" (click)="viewReportTop()">View full report</button>
                    </div>
                    
                }
            </div>
            <div class="top_list">
                <div class="top_list_header flex items-center">
                    <div class="name header-block">
                        Name
                        @if (top) {
                            <button
                                (click)="onSort(topActive, 'name', sortable.sort == 'incr' ? 'decr' : 'incr')"
                                class="filter-sort-btn"
                            >
                                <i class="fa-solid text-gray-500"
                                [ngClass]="{
                                    'fa-chevron-down': sortable.sort == 'incr' || sortable.field !== 'name',
                                    'fa-chevron-up': sortable.sort == 'decr' && sortable.field == 'name'
                                    }"
                                ></i>
                            </button>
                        }
                    </div>
                    <div class="orders header-block">
                        Orders
                        @if (top) {
                            <button
                                (click)="onSort(topActive, 'ordersCount', sortable.sort == 'incr' ? 'decr' : 'incr')"
                                class="filter-sort-btn"
                            >
                                <i class="fa-solid text-gray-500"
                                [ngClass]="{
                                    'fa-chevron-down': sortable.sort == 'incr' || sortable.field !== 'ordersCount',
                                    'fa-chevron-up': sortable.sort == 'decr' && sortable.field == 'ordersCount'
                                    }"
                                ></i>
                            </button>
                        }
                    </div>
                    <div class="vehicles header-block">
                        Vehicle
                        @if (top) {
                            <button
                                (click)="onSort(topActive, 'vehiclesCount', sortable.sort == 'incr' ? 'decr' : 'incr')"
                                class="filter-sort-btn"
                            >
                                <i class="fa-solid text-gray-500"
                                [ngClass]="{
                                    'fa-chevron-down': sortable.sort == 'incr' || sortable.field !== 'vehiclesCount',
                                    'fa-chevron-up': sortable.sort == 'decr' && sortable.field == 'vehiclesCount'
                                    }"
                                ></i>
                            </button>
                        }
                    </div>
                    <div class="avg_booking header-block">
                        Avg Booking
                        @if (top) {
                            <button
                                (click)="onSort(topActive, 'avgBooking', sortable.sort == 'incr' ? 'decr' : 'incr')"
                                class="filter-sort-btn"
                            >
                                <i class="fa-solid text-gray-500"
                                [ngClass]="{
                                    'fa-chevron-down': sortable.sort == 'incr' || sortable.field !== 'avgBooking',
                                    'fa-chevron-up': sortable.sort == 'decr' && sortable.field == 'avgBooking'
                                    }"
                                ></i>
                            </button>
                        }
                    </div>
                    <div class="total_amount header-block">
                        Total Amount
                        @if (top) {
                            <button
                                (click)="onSort(topActive, 'totalAmount', sortable.sort == 'incr' ? 'decr' : 'incr')"
                                class="filter-sort-btn"
                            >
                                <i class="fa-solid text-gray-500"
                                [ngClass]="{
                                    'fa-chevron-down': sortable.sort == 'incr' || sortable.field !== 'totalAmount',
                                    'fa-chevron-up': sortable.sort == 'decr' && sortable.field == 'totalAmount'
                                    }"
                                ></i>
                            </button>
                        }
                    </div>
                </div>
                @if(loading){
                    <div class="top_list_data">
                        @for (dispatcher of preloadItems; track $index) {
                            <div class="top_list_item flex items-center">
                                <div class="name">
                                    <div class="top_list_item_pre_name">
                                        <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                    </div>
                                </div>
                                <div class="orders">
                                    <div class="top_list_item_pre_orders">
                                        <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                    </div>                                </div>
                                <div class="vehicles">
                                    <div class="top_list_item_pre_vehicles">
                                        <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                    </div>                                </div>
                                <div class="avg_booking">
                                    <div class="top_list_item_pre_avg">
                                        <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                    </div>                                </div>
                                <div class="total_amount">
                                    <div class="top_list_item_pre_total">
                                        <app-preloader [type]="'LARGE_LIST'" [count]="1"></app-preloader>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                } @else {
                    @if(topActive === 'dispatchers'){
                        <div class="top_list_data">
                            @for (dispatcher of top?.dispatchers; track $index) {
                                <div class="top_list_item">
                                    <div class="name data-block">{{dispatcher?.name}}</div>
                                    <div class="orders data-block">{{dispatcher?.ordersCount}}</div>
                                    <div class="vehicles data-block">{{dispatcher?.vehiclesCount}}</div>
                                    <div class="avg_booking data-block">
                                        <i class="fa-solid fa-dollar-sign"></i>
                                        <span>{{transformNumbersSliceDollar(dispatcher?.avgBooking | myCurrency)}}</span>
                                    </div>
                                    <div class="total_amount data-block">
                                        <i class="fa-solid fa-dollar-sign"></i>
                                        <span>{{transformNumbersSliceDollar(dispatcher?.totalAmount | myCurrency)}}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    @if(topActive == 'customers'){
                        <div class="top_list_data">
                            @for (customer of top?.customers; track $index) {
                                <div class="top_list_item">
                                    <div class="name data-block">{{customer?.name}}</div>
                                    <div class="orders data-block">{{customer?.ordersCount}}</div>
                                    <div class="vehicles data-block">{{customer?.vehiclesCount}}</div>
                                    <div class="avg_booking data-block">
                                        <i class="fa-solid fa-dollar-sign"></i>
                                        <span>{{transformNumbersSliceDollar(customer?.avgBooking | myCurrency)}}</span>
                                    </div>
                                    <div class="total_amount data-block">
                                        <i class="fa-solid fa-dollar-sign"></i>
                                        <span>{{transformNumbersSliceDollar(customer?.totalAmount | myCurrency)}}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    @if(topActive == 'drivers'){
                        <div class="top_list_data">
                            @for (driver of top?.drivers; track $index) {
                                <div class="top_list_item">
                                    <div class="name data-block">{{driver?.name}}</div>
                                    <div class="orders data-block">{{driver?.ordersCount}}</div>
                                    <div class="vehicles data-block">{{driver?.vehiclesCount}}</div>
                                    <div class="avg_booking data-block">
                                        <i class="fa-solid fa-dollar-sign"></i>
                                        <span>{{transformNumbersSliceDollar(driver?.avgBooking | myCurrency)}}</span>
                                    </div>
                                    <div class="total_amount data-block">
                                        <i class="fa-solid fa-dollar-sign"></i>
                                        <span>{{transformNumbersSliceDollar(driver?.totalAmount | myCurrency)}}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                }
            </div>
        </div>
    </div>
</div>




